import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_Home_Automation/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Mosquitto Broker Installation on Windows",
  "path": "/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "Eclipse Mosquitto is an open source message broker that implements the MQTT protocol versions 3.1 and 3.1.1. Mosquitto is lightweight and is suitable for use on all devices from low power single board computers to full servers. The MQTT protocol provides a lightweight method of carrying out messaging using a publish / subscribe model.This makes it suitable for Internet of Things messaging such as with low power sensors or mobile devices such as phones, embedded computers or microcontrollers. The Mosquitto project also provides a C library for implementing MQTT clients, and the very popular mosquitto_pub and mosquitto_sub command line MQTT clients. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Mosquitto Broker Installation on Windows' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='Eclipse Mosquitto is an open source message broker that implements the MQTT protocol versions 3.1 and 3.1.1. Mosquitto is lightweight and is suitable for use on all devices from low power single board computers to full servers. The MQTT protocol provides a lightweight method of carrying out messaging using a publish / subscribe model.This makes it suitable for Internet of Things messaging such as with low power sensors or mobile devices such as phones, embedded computers or microcontrollers. The Mosquitto project also provides a C library for implementing MQTT clients, and the very popular mosquitto_pub and mosquitto_sub command line MQTT clients. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_Heimautomatisierung/Mosquitto_Installation_unter_Windows/' locationFR='/fr/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "mosquitto-broker-installation-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#mosquitto-broker-installation-on-windows",
        "aria-label": "mosquitto broker installation on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mosquitto Broker Installation on Windows`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#Mosquitto-on-Windows"
        }}>{`Mosquitto on Windows`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Installing-Mosquitto"
            }}>{`Installing Mosquitto`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Testing-the-MQTT-Server-with-MQTTfx"
            }}>{`Testing the MQTT Server with MQTT.fx`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Troubleshooting"
                }}>{`Troubleshooting`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#MQTT-Binding-for-OpenHAB"
        }}>{`MQTT Binding for OpenHAB`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "mosquitto-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mosquitto-on-windows",
        "aria-label": "mosquitto on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mosquitto on Windows`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/eclipse/mosquitto"
      }}>{`Eclipse Mosquitto`}</a>{` is an open source (EPL/EDL licensed) message broker that implements the MQTT protocol versions 3.1 and 3.1.1. Mosquitto is lightweight and is suitable for use on all devices from low power single board computers to full servers.`}</p>
    <p>{`The MQTT protocol provides a lightweight method of carrying out messaging using a publish/subscribe model. This makes it suitable for Internet of Things messaging such as with low power sensors or mobile devices such as phones, embedded computers or microcontrollers.`}</p>
    <h3 {...{
      "id": "installing-mosquitto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installing-mosquitto",
        "aria-label": "installing mosquitto permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing Mosquitto`}</h3>
    <p>{`First we need to `}<a parentName="p" {...{
        "href": "https://mosquitto.org/download/"
      }}>{`Download`}</a>{` the latest binary and execute it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/64e4a403ec431506b082410f8e1089e2/e9beb/Mosquitto_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB4klEQVQoz5WSz2sTQRTHJzcNAQ9e1Jtg/WPEk/QmVYvoxYuCUKjFgJjm5CGeBC+ePYlVLEpVjLXSFJqgqSHLkhiNJLPZX9mdXzuz78kmWnOogh/mMMPjy5v3vl/iOI7v+wNKv/f74yjC/4F4GSOjNUCaGm2MNgAp4vTAv8XYsqDWwGYb619wt4nbdaQuqgS5RJkYLmLG4jgWQvDfCM4ZY1Iqcn/73a2NZ8tvX93ZqZYbH8v1D+XG5t36+9JudaX2+tHnGgvHfhBIpdI0NTMAADl0cj4/tzB3dvHKvYcvv7ae23trVnOt/Wm909oKhjv0h2BMCKmS5IBv5w8vHDu6dO7MyoPKBkrGvID7Yez63AtAJVJKpRQCgDGp1mmiAf7sgRSOLOZyNwm5dru4Phh8syy70+natt1uWyPXG3MWhqEWQsexjpkeR2DMrPgSIUVClorFp34wpM6Icy4nAEAshNb6r9vOOpMLhNxYXX2DiEliZsuR4L/EB5lG8oXzudw8IZdLpReIaEw6W97vvD/q9AITyKnTV08cv1goXK9UqllnbVJjFJeJzLwJogyllBQTJuNMPRdCkL5DqesM3RH1XOq6NAyHvd7e4yfd6qbV7di9XhRFQmSpYIxxloWEZa8sKD8BEg9yHiGp0UwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64e4a403ec431506b082410f8e1089e2/e4706/Mosquitto_01.avif 230w", "/en/static/64e4a403ec431506b082410f8e1089e2/d1af7/Mosquitto_01.avif 460w", "/en/static/64e4a403ec431506b082410f8e1089e2/b6582/Mosquitto_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/64e4a403ec431506b082410f8e1089e2/a0b58/Mosquitto_01.webp 230w", "/en/static/64e4a403ec431506b082410f8e1089e2/bc10c/Mosquitto_01.webp 460w", "/en/static/64e4a403ec431506b082410f8e1089e2/87ca7/Mosquitto_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64e4a403ec431506b082410f8e1089e2/81c8e/Mosquitto_01.png 230w", "/en/static/64e4a403ec431506b082410f8e1089e2/08a84/Mosquitto_01.png 460w", "/en/static/64e4a403ec431506b082410f8e1089e2/e9beb/Mosquitto_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/64e4a403ec431506b082410f8e1089e2/e9beb/Mosquitto_01.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Installer will ask to download and install the latest version of `}<a parentName="p" {...{
        "href": "http://slproweb.com/products/Win32OpenSSL.html"
      }}>{`Win32OpenSSL`}</a>{` and copy all `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.dll`}</code>{`'s to the Mosquitto install directory:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e401e060282a1ffa7fbce31939e9420/e9beb/Mosquitto_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACHElEQVQoz1WOSW/TYBiE/Zf5BVygIgipSBy4ABJc4AS0RFRpBQmJ3Ia0SdrSJvHy2Y6Xb99sN3a2xii0IDEaaUav9Lwao7FXO6o/bezXGvu1w71a4+NOs/6s03jRbb3ptd/22u+6zVetr7s/Dp63//Nu6+ClkSXsxkXzSBQeypFYleuUZqtiXd1W1eqPl/dlvazK+Wa+2JSL28WyKsrSYBZA3QkZuqR3fXZ13gSjD52TT6ed/YH5ZWh+7rfrF92j0eBwPOw5VzDy4zgMwwChmDFsCJXztJJ5pYrV61+nD1r1R9cnT7zhY/tsx+nvOP0nYLgbjR6Ou+/Hg5mWlOWUKqmYFNxgTCFccFYmOFOuv7warS2ncr2NAzYOuLXdjTetYjSfRlEUue4kCCzfs6QUQjCDc8VYKeVcZrNgOBi3ji+b5vm3zmXTvPhuguOfYa/nmya+uPTi0PfsaQDiOEzTVEmxhQkrhZjHMA0gBpBPQjIJqRXR8RR7WLJslhdFOiv8MADAimOfEEgp5pwanGlC55wvmcgwJVmaaqXvlGqtlFJSabU9RTChJCFYJ5ByTrazGVOYLLhYJjiHeAurv/C/F3c5TSKYhBgjCJMsS6XkBucpZQspV0zcIEK01lLda0uk6R2eZTkIPMce+b7tOiMhuNbSwAhjwikVCUIxTJRUQggppdYaIegAvz8J7AD6nmc5tm1dA2AhlGAMCYa/Aad8QN9Y4PhKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e401e060282a1ffa7fbce31939e9420/e4706/Mosquitto_02.avif 230w", "/en/static/5e401e060282a1ffa7fbce31939e9420/d1af7/Mosquitto_02.avif 460w", "/en/static/5e401e060282a1ffa7fbce31939e9420/b6582/Mosquitto_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e401e060282a1ffa7fbce31939e9420/a0b58/Mosquitto_02.webp 230w", "/en/static/5e401e060282a1ffa7fbce31939e9420/bc10c/Mosquitto_02.webp 460w", "/en/static/5e401e060282a1ffa7fbce31939e9420/87ca7/Mosquitto_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e401e060282a1ffa7fbce31939e9420/81c8e/Mosquitto_02.png 230w", "/en/static/5e401e060282a1ffa7fbce31939e9420/08a84/Mosquitto_02.png 460w", "/en/static/5e401e060282a1ffa7fbce31939e9420/e9beb/Mosquitto_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e401e060282a1ffa7fbce31939e9420/e9beb/Mosquitto_02.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ba035dfe84971d127f8278d0abc13ad7/e9beb/Mosquitto_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACWUlEQVQoz32Ry2oUQRSG+8n0LVwEk60bdz6EFxSFbMQ3MBgMGEVMFpEQQohmhGBuc+1xunu6q6rr3t3Vpy7SM2HMyo/iLA58nP/UiZxzPnTo1tzf+nhve/tJ78uDw9214921H1/Xz/bWe/sbvb2N3v762W19ePZt+SIuhK6qkpAcof3z4iqFYCpXl4YLp7Rv2lZpQzkI5eum5SLczuqItFZSSgDQVVWbquKEcx6CA2uV1kpLcNZ5D9Za56zr+kJpKmQLEBE0GQ+u0iQdTWImsSBjQlhVVVLKutaSJiWlrTGracF7KWWXV+moZPl4NEiShDFZSdSwieKqUaqSqtY6TeNBf4AxXlh+WcsFlNII02w87BNEIAThTVELCTaEYEOQrcEIpUkKAEvzjkxoSaP89CQ+OCgvr9n7D/j56/mrTfX0hXz0WL7ZxMcnhJDZ9A/B5E5qL7hgjAohIvyrlxwdlReX9fdDurObbO3M3r5Tz17Wnz7j059ZjtIkIZiYxqxidztzrrSOchyPR33BpQ2Bel84J2wXG7zHSk3TYjgYDvsDSulqMqWUYMy5iAqU929u4jg2bVsyPhpOGOlCQtMQhPIc9a/70zi21t2RS4wxpSwSanFgYwzAZJac/76IJxOEUJZl83xe5PE0njLGnPsnc8bZgqht22XXAFAhZrPZfJ5ba6uqLoo8nQ6zNKOUNk2zkpVSQgil1EL2t7Kqa1rSsuzWAwCCMbTQ1MYufmGFXeCciwBgNVlWGmOUJamSyjknhAj/JVpdvwVgSilVUcpwWTamBWiD9/+R/wK+AEBXBozu5AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba035dfe84971d127f8278d0abc13ad7/e4706/Mosquitto_03.avif 230w", "/en/static/ba035dfe84971d127f8278d0abc13ad7/d1af7/Mosquitto_03.avif 460w", "/en/static/ba035dfe84971d127f8278d0abc13ad7/b6582/Mosquitto_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ba035dfe84971d127f8278d0abc13ad7/a0b58/Mosquitto_03.webp 230w", "/en/static/ba035dfe84971d127f8278d0abc13ad7/bc10c/Mosquitto_03.webp 460w", "/en/static/ba035dfe84971d127f8278d0abc13ad7/87ca7/Mosquitto_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba035dfe84971d127f8278d0abc13ad7/81c8e/Mosquitto_03.png 230w", "/en/static/ba035dfe84971d127f8278d0abc13ad7/08a84/Mosquitto_03.png 460w", "/en/static/ba035dfe84971d127f8278d0abc13ad7/e9beb/Mosquitto_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ba035dfe84971d127f8278d0abc13ad7/e9beb/Mosquitto_03.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And the same with `}<a parentName="p" {...{
        "href": "ftp://sources.redhat.com/pub/pthreads-win32/dll-latest/dll/x86/"
      }}>{`this pthread.dll`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e70352eb48c368f23b087bb3a50b5ca4/e9beb/Mosquitto_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABxUlEQVQoz4WRa2+bMBSG8/9/yKS10jSp6rZK+zqlC8lSIMkCYQk0gYDxlRrbgA0TlLabusujI+v94Efn6JyJ7di73fY+CpfLhWVZs5m12awd1znsfywW31zXnVvWfDb3PO/r7dSaz5d3d8aYbmACIQCggrlCSCglhZRKKc55n4WolKqUEkKUZakqVVVKSmmMaQcmrTFZSkoutW4JY2LAvKAfv2qtm6bRWhut67oeO9Oy9Q5NSkylTZqmvr/LUiClkn1DKYTs89Ct67qqqRKQKqVGOZu6/ufAv/GAExb8AQAAYQ4hxBihvjDCCOSQUcY5J4wdz6mUcpTPXxzvxvM/fT/bUXo6rlbeer1buVvH3a7Wvu1st2svjsLj/fFwCOM4poRwzkeZlG0MOsi60rTZ9HZzcbV699F+e+VefrAvru3La/fN+4PtoYI/FKwoCoxxWZaj3A1769quriuCMUUYQ0T6FyOIH0OOCMaEEkIp/V1+oq5rjDEboIz+Evp65l8y/R9/lRFC5BXsCUr7cQghf5bzvD8S6g80AiE8nU5JksRxnGVZHMdJkrxs+1lumga+AgAQBLsoioIgSJJkv9+HYSiEeJR/AmfX1LSgP3sFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e70352eb48c368f23b087bb3a50b5ca4/e4706/Mosquitto_04.avif 230w", "/en/static/e70352eb48c368f23b087bb3a50b5ca4/d1af7/Mosquitto_04.avif 460w", "/en/static/e70352eb48c368f23b087bb3a50b5ca4/b6582/Mosquitto_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e70352eb48c368f23b087bb3a50b5ca4/a0b58/Mosquitto_04.webp 230w", "/en/static/e70352eb48c368f23b087bb3a50b5ca4/bc10c/Mosquitto_04.webp 460w", "/en/static/e70352eb48c368f23b087bb3a50b5ca4/87ca7/Mosquitto_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e70352eb48c368f23b087bb3a50b5ca4/81c8e/Mosquitto_04.png 230w", "/en/static/e70352eb48c368f23b087bb3a50b5ca4/08a84/Mosquitto_04.png 460w", "/en/static/e70352eb48c368f23b087bb3a50b5ca4/e9beb/Mosquitto_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e70352eb48c368f23b087bb3a50b5ca4/e9beb/Mosquitto_04.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "testing-the-mqtt-server-with-mqttfx",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testing-the-mqtt-server-with-mqttfx",
        "aria-label": "testing the mqtt server with mqttfx permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the MQTT Server with MQTT.fx`}</h3>
    <p><a parentName="p" {...{
        "href": "https://mqttfx.jensd.de/index.php/download"
      }}>{`Download MQTT.fx`}</a>{` as a MQTT Client for MQTT testing/debugging. Install and start the program and connect it to our local MQTT server:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ba63b1613def4cde8f261d091665283/e9beb/Mosquitto_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAyklEQVQY03XITUrDQABA4RwjpVsvYGaSNDNNJpkfJ9Gq0KUgFdx7AIOtJ1CM9bxPaKHooouPBy+5+Ngyn7bMf95J9ztm+92ps2kk/RpJv/+8c6Y30s+RxMZb1qsb7vqB2Fl66/DO8ahqnlcb+ocXgmnxjcFUCqP0WW1jSLrNK379hHMtpj2yIRDqBrvsyLWhKMsDmecnQgqElP/IsiCpfY+P13jviDEe9MNAiFcoY1HhHiELsuySTIijLKNqA7o2KFWhtGapNXKx4BfUkJNXMi/NKgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ba63b1613def4cde8f261d091665283/e4706/Mosquitto_05.avif 230w", "/en/static/5ba63b1613def4cde8f261d091665283/d1af7/Mosquitto_05.avif 460w", "/en/static/5ba63b1613def4cde8f261d091665283/b6582/Mosquitto_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ba63b1613def4cde8f261d091665283/a0b58/Mosquitto_05.webp 230w", "/en/static/5ba63b1613def4cde8f261d091665283/bc10c/Mosquitto_05.webp 460w", "/en/static/5ba63b1613def4cde8f261d091665283/87ca7/Mosquitto_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ba63b1613def4cde8f261d091665283/81c8e/Mosquitto_05.png 230w", "/en/static/5ba63b1613def4cde8f261d091665283/08a84/Mosquitto_05.png 460w", "/en/static/5ba63b1613def4cde8f261d091665283/e9beb/Mosquitto_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ba63b1613def4cde8f261d091665283/e9beb/Mosquitto_05.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/e9beb/Mosquitto_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/klEQVQoz5WQbU/aUBiG+QnE6Bb3dVkppeWc55y29u2UtryKtlBASCmKOJyLCRkkkzk/uZgtI27/eQGGA3VLduX5dp4r93nuxPaXSfL6Ink7St6Nk3fjra+TrW8fl7M9u9qZTV/Mprs/Pr/6efMwL+8/7d5Pd75fJShSUCqNOB5xKcSlFEGUUxx981pN8wVN7QTBoBufRnHcPGrX65162AmCy5N3Z/UThzmJTCYjStJqRJAVp/Xe7Y7znVG1O3HcClNkS1H3MChZSUEIYWyZjkYN0PWEuAkQapQae+WjVjWK2uf9VjTqHZ+3O4Ow8bbVHh76DCALmFIghvZEBgCRRxJfzIqhqY/i7u2H8WUUnYXhsNUa+v6B6xmWRYHM5cw6ggBUdo+vbT/O2SzvukXPy+dyh/v7QbVact2841iMabpOyFM5kwFCZJ1RRWOMmZblB0G/37csS5Kkxb0YAAghlNDnZABKMGDsel61etBoNnu9HmMMAxBKCV2xTBY2AQBZludPphmGYa1WC8PQNM152jr/llVVzdl2oVjyfd80LfqIv8kPCwihSqUSx3EURbZtb4Qv5fQmGOP1AE2bN8cY0xcN/59MFvxueIks/5F5nl+XEULrP3/mVCTNFzAQTU3wgsCv4DjOMIxyufy420UiAayaucLgpljxh653kc//ArspzRhUuXoJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/e4706/Mosquitto_06.avif 230w", "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/d1af7/Mosquitto_06.avif 460w", "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/b6582/Mosquitto_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/a0b58/Mosquitto_06.webp 230w", "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/bc10c/Mosquitto_06.webp 460w", "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/87ca7/Mosquitto_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/81c8e/Mosquitto_06.png 230w", "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/08a84/Mosquitto_06.png 460w", "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/e9beb/Mosquitto_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2394ec6901cdb4d69f1d3ae790de3a6f/e9beb/Mosquitto_06.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/198a1461a2bb8e08c096b87246079157/e9beb/Mosquitto_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.130434782608692%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA00lEQVQI11XIzUrDQBiF4dyGNAi9AGmTb34z+eYnYzIqilQacGEswYQsXLgTpBHcC03xiqXBjfDwcjjRctovf77i42d8HOeexvlhH0/jYu78/zeNi++Ps8MYBe0C4mWWn0jlpNpI82pud6Z8uL4pFVomHJd/hCy4tFwG1Js7G9nHN+NMrrVCNNai1ipTgjLBqFQZ45wyRhkjlKTJKgVICSQAlJAaRdS03VPTtG3b933XdcMwbLd1+fyO97v16gKAAJA0WfPchfqlqirvfbgKvvDUFb+N10WzrnckHAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/198a1461a2bb8e08c096b87246079157/e4706/Mosquitto_07.avif 230w", "/en/static/198a1461a2bb8e08c096b87246079157/d1af7/Mosquitto_07.avif 460w", "/en/static/198a1461a2bb8e08c096b87246079157/b6582/Mosquitto_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/198a1461a2bb8e08c096b87246079157/a0b58/Mosquitto_07.webp 230w", "/en/static/198a1461a2bb8e08c096b87246079157/bc10c/Mosquitto_07.webp 460w", "/en/static/198a1461a2bb8e08c096b87246079157/87ca7/Mosquitto_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/198a1461a2bb8e08c096b87246079157/81c8e/Mosquitto_07.png 230w", "/en/static/198a1461a2bb8e08c096b87246079157/08a84/Mosquitto_07.png 460w", "/en/static/198a1461a2bb8e08c096b87246079157/e9beb/Mosquitto_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/198a1461a2bb8e08c096b87246079157/e9beb/Mosquitto_07.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can test the MQTT Service by `}<strong parentName="p">{`subscribing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc042f36b6d4185117f2c69074b1d950/e9beb/Mosquitto_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA6klEQVQY012L206DMABA+SChWHqhXS8MZmFU7bAyzXwzsEvUjC1729u+3EiMWTw5D+flBHT4SE7b+NhHQweG/tfDDhx38WEDhnW47/4Z7Tvw+X5z/gq4MnORP+h5TrkiVJNUUiaV0VllZ6bUmUBYYiIxEQiLMThMCkKylyqoi+l6tT1tLm+Ltrq3j01jSjOTSlOGEYIYJRj/SDDmHKcpooTld4wrUJdBX9tF41zjvH/y3vu2XTq3FMUznzIIYfIHohOdMkYJUbZVhYW2DJhSURgKIY0xSkyUfc3cKr4F8fU4AkfGiJMwjMriG624TxWyIlmIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc042f36b6d4185117f2c69074b1d950/e4706/Mosquitto_08.avif 230w", "/en/static/bc042f36b6d4185117f2c69074b1d950/d1af7/Mosquitto_08.avif 460w", "/en/static/bc042f36b6d4185117f2c69074b1d950/b6582/Mosquitto_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc042f36b6d4185117f2c69074b1d950/a0b58/Mosquitto_08.webp 230w", "/en/static/bc042f36b6d4185117f2c69074b1d950/bc10c/Mosquitto_08.webp 460w", "/en/static/bc042f36b6d4185117f2c69074b1d950/87ca7/Mosquitto_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc042f36b6d4185117f2c69074b1d950/81c8e/Mosquitto_08.png 230w", "/en/static/bc042f36b6d4185117f2c69074b1d950/08a84/Mosquitto_08.png 460w", "/en/static/bc042f36b6d4185117f2c69074b1d950/e9beb/Mosquitto_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc042f36b6d4185117f2c69074b1d950/e9beb/Mosquitto_08.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Going back to the `}<strong parentName="p">{`Publish`}</strong>{` tab and sending a message:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/e9beb/Mosquitto_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA1ElEQVQI10XCbW6CMAAAUM5jVlwZLa1KKzDaNdFEi4lmiB9ZJkNEgiX80hPs0otzyV6ehZrC7QrYlcAcbJPbJgcmB+3Rbst++wVM/tQc/l7+g/qjdztbTC7DQAR0MCaPlBHKuORjORVSBZGPPeYR5hEfIR9hhr0hwjEdhCtlvS6rabKXIo7Vm1BKTSaRFBEPw1HkQgjgcx/Ce8dBQ44IcTFClLjOC1CxdWH8WydNWZ7quvp9qs9d0dyO10wvZnqu7+Y6Waw/q2y7W6dpttls31Oxy34ACz1IY3L5ZSwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/17cd79b3c21a0b504d5ee4337105d2e2/e4706/Mosquitto_09.avif 230w", "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/d1af7/Mosquitto_09.avif 460w", "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/b6582/Mosquitto_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/17cd79b3c21a0b504d5ee4337105d2e2/a0b58/Mosquitto_09.webp 230w", "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/bc10c/Mosquitto_09.webp 460w", "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/87ca7/Mosquitto_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/17cd79b3c21a0b504d5ee4337105d2e2/81c8e/Mosquitto_09.png 230w", "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/08a84/Mosquitto_09.png 460w", "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/e9beb/Mosquitto_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/17cd79b3c21a0b504d5ee4337105d2e2/e9beb/Mosquitto_09.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The message should show up in the `}<strong parentName="p">{`Subscribe`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/90a7789361ad75c416570f838fafe8db/e9beb/Mosquitto_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB9UlEQVQoz42R327TMBTG8x7rLuCW0sTHf2I7dhInTtukRaWdmGBtUYdAhT0BtA2bNMQFaAVxsQJPwGOithtrt4L49JN1dKTj7/Oxc+9icv/b2f7Xcv/L7JppZT6pzCd7F5PKTXOD+bTy+e3ep3dOI0obgcyktIGyUlkZJIHJ6r0ibR7kRRbomHHjiy24bIRxpwicqNlJX57H3WOtpEltbBKpNeGC+JwJQRjDlK4gQDEsT+KBhwmmgjrPR8fDF+P+cNTvHw0Gg+HR01azleUHJjShzyIhYiFizo0K61lRj6IsMr3+Sftxvx7GDqM0kLzZaFhrTda0veHpq9eLSXk5LS+nsxtm5ffydDErF7P3P84+/Dz/+GtaOgghQojWWkrpS8VNHvoiIcRQehuyYlmQde3gldC1wKshAPf/uBreECGry9bA30EIOZ7nAQDGGGDZwQAIY48QtALwv+REUSSEAAAhhFZKBEpTFlYf6mpVP6hyBGSXAMB1XSeQkjEGAIwxIQRm7M2Tw5PR8WGn86zb7QjpIUS2DRFCSqnxeLzc9p/YAOABDIvWsP2ol9muMQVlCG5nBwDOeZqmzp110SRN/VqN1VzfdQnsfvXSxvPuDpM4yxAlCGO0FRXWGde6WtjO4fV/bfoopZIksda22+08z5M0VVr/BgCA3ryBilKXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90a7789361ad75c416570f838fafe8db/e4706/Mosquitto_10.avif 230w", "/en/static/90a7789361ad75c416570f838fafe8db/d1af7/Mosquitto_10.avif 460w", "/en/static/90a7789361ad75c416570f838fafe8db/b6582/Mosquitto_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/90a7789361ad75c416570f838fafe8db/a0b58/Mosquitto_10.webp 230w", "/en/static/90a7789361ad75c416570f838fafe8db/bc10c/Mosquitto_10.webp 460w", "/en/static/90a7789361ad75c416570f838fafe8db/87ca7/Mosquitto_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90a7789361ad75c416570f838fafe8db/81c8e/Mosquitto_10.png 230w", "/en/static/90a7789361ad75c416570f838fafe8db/08a84/Mosquitto_10.png 460w", "/en/static/90a7789361ad75c416570f838fafe8db/e9beb/Mosquitto_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/90a7789361ad75c416570f838fafe8db/e9beb/Mosquitto_10.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h4>
    <p><strong parentName="p">{`Make sure that the Mosquitto Service is running`}</strong>{`: During the installation of Mosquitto you were given the option to automatically run the server as a Windows Service. This option is checked by default. You can verify that the service is actually active in your Windows Task Manager:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d54a0b5387bd6e503363f5005866bbab/e9beb/Mosquitto_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABqElEQVQoz22Q3Y+UMADE+TN9MfHpTPSP80k3ObOud5eYrKe73AfrAgu0pUBZKOWzFEpbo3tvOvk9TTKTyVjvt9evNx/efPt4ZX95+7B5wf7Lw/+wN1f79Tt78+p+ZRVpxlJSomQoaHcuOGW8rARrxqpW3aAnaf6R0toY4zTUQhlp+l7IZVJLx3nT9zjNUpLXXc/aVkg5K5US0nHej2NGSM/5OE9G6y2JLR+g0/HYNs1Lq1piAAPfy9JE8OFiYgRrxrRWlLG662aljDG7Krd+uvB+f3jy4AkXeSPyetg7/nb//CtMcNFWg6y4ejyGQVyc28n7vgt+7HI/0KTYuo61c8HBDUFKy24q2xGfmeNF9pPn/gnXVT81Qh88ECVFLXT+9Q5+WuW3d8365uZ6ZfkAMlpe5mmt5TynMUYRyJKEFueGMWNMiuOaMaN13XXuKcCEzKO4jUPLA3FOyOVDbYxcFghQFIQJTsuSTmIyxoAIUlotSlWUQgB8/8RZvfYcy4cxRkgIYYyRUvZdj1CMIIzCEALYNq2UEqOYVWyeZ4yx53qO4wys/uw+/wbJAOAmTGPjRAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d54a0b5387bd6e503363f5005866bbab/e4706/Mosquitto_11.avif 230w", "/en/static/d54a0b5387bd6e503363f5005866bbab/d1af7/Mosquitto_11.avif 460w", "/en/static/d54a0b5387bd6e503363f5005866bbab/b6582/Mosquitto_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d54a0b5387bd6e503363f5005866bbab/a0b58/Mosquitto_11.webp 230w", "/en/static/d54a0b5387bd6e503363f5005866bbab/bc10c/Mosquitto_11.webp 460w", "/en/static/d54a0b5387bd6e503363f5005866bbab/87ca7/Mosquitto_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d54a0b5387bd6e503363f5005866bbab/81c8e/Mosquitto_11.png 230w", "/en/static/d54a0b5387bd6e503363f5005866bbab/08a84/Mosquitto_11.png 460w", "/en/static/d54a0b5387bd6e503363f5005866bbab/e9beb/Mosquitto_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d54a0b5387bd6e503363f5005866bbab/e9beb/Mosquitto_11.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you are running Mosquitto just for testing, you might have chosen to exclude the Windows Service during the installation step. In this case just run the `}<em parentName="p">{`mosquitto.exe`}</em>{` from your Command Line (you might have to start CMD as Administrator):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1511f4e45b450380c0e6c1482e9f8eea/e9beb/Mosquitto_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB70lEQVQoz3VQW2sTQRSeTbJJvCHksmmg0OIfqBpB0JIX24ZEEAR/lz6a2koCvpTeaMUXBd8EH8WfULWbNJvM7M7uzJnZmcxIEvsg1Y9zDufCd/jOQR+/n2E85CwRUhoztVdgjPlHxxjKBPr2I4ZUS8BRRBPGGWOEkCRhlFIAUEprPdVaG2OkTAEE5xxAWGNixlEi0jAMpZRRSGgYcA5KqekcAAKTEAAiStVszXTmc1hrtVLIH1/4vp+majwOhoMz/3ygtV7IAwCCCaU0CqM0Ta21Qgil1GKqtUZBSAe+zxjDEzy6GNEospdHCgBCSBzHnPOFcs75X2QcEjyZCBAY41EQDIajVKnFkwAATyYxjQnBISFKqSSOFxKMMUoppBVRqTDWRj9/BV++nn/6PA3Gf3YrxeJEgmBJkkpprU2lXBxsrZ1qjV7sd9t73Wcnvfb+9kbv1Vb/ZXvv9dP3/c5pr3W8+2S/u3nwZuNge+tot3Pa3zzcaR2/7Zz0Woc7zz+8Q5m659QqTrXk1KvO8hKqe8grods30a3rKJ9FhdylZeflLGaKLkJo7fFD9MCr3fdqa+XKvXK1UfXulsoNb6lR8W64+RxyXCdz1Yq5GbnZbKI7xWsrbn7Fza/mC7MkX1h1C8s510H/RTabRQg9Wl//De9+vFI8YmCDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1511f4e45b450380c0e6c1482e9f8eea/e4706/Mosquitto_12.avif 230w", "/en/static/1511f4e45b450380c0e6c1482e9f8eea/d1af7/Mosquitto_12.avif 460w", "/en/static/1511f4e45b450380c0e6c1482e9f8eea/b6582/Mosquitto_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1511f4e45b450380c0e6c1482e9f8eea/a0b58/Mosquitto_12.webp 230w", "/en/static/1511f4e45b450380c0e6c1482e9f8eea/bc10c/Mosquitto_12.webp 460w", "/en/static/1511f4e45b450380c0e6c1482e9f8eea/87ca7/Mosquitto_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1511f4e45b450380c0e6c1482e9f8eea/81c8e/Mosquitto_12.png 230w", "/en/static/1511f4e45b450380c0e6c1482e9f8eea/08a84/Mosquitto_12.png 460w", "/en/static/1511f4e45b450380c0e6c1482e9f8eea/e9beb/Mosquitto_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1511f4e45b450380c0e6c1482e9f8eea/e9beb/Mosquitto_12.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You know that Node-RED has detected the MQTT server, when your `}<strong parentName="p">{`MQTT Nodes`}</strong>{` say that they are `}<em parentName="p">{`connected`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/e9beb/Mosquitto_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABT0lEQVQY03XNv07CQAAG8L5Pe9ewCoOLLryXb+ALKBJXF6IkDhIMKHEgQi0HFGkpd9e/0EKhd1dqRAcT09/0Dd+XTzotn1TPzqvVaqVSLpVKAABZliGEqqoCABRFUY9kWZYVBUL4UwAAQAil1Wq1CkPBGWfffD8glMZxnGy3nutiTJIk2e12eLkkhKyjKAgC27Z934+iSDKtxWgyo36IKSWEbOPYcxxKCMWYMSb4L3H0N+/3ewlNzIfHXqPZedcmrus462ju0BnBNPAzwQXn2ZH4J01TifT76Lau12uL3iv2vDfTag7R/QANTMJSccgPWQHGmPTpGR3zuW20EB55jh+tQ2vaQNqVptWQdhPHbpblhWNCiT7WP9CHac1d1+OceeF42r1DjWtt2LKtuRCicLw0lnpHH7YH9tTebDd5lvE8d566+sVl/8UgBs7zwucvYUOQBbjZy3cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eeba646b6f8fab5f50b9b7edd4e29909/e4706/Mosquitto_13.avif 230w", "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/d1af7/Mosquitto_13.avif 460w", "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/b6582/Mosquitto_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eeba646b6f8fab5f50b9b7edd4e29909/a0b58/Mosquitto_13.webp 230w", "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/bc10c/Mosquitto_13.webp 460w", "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/87ca7/Mosquitto_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eeba646b6f8fab5f50b9b7edd4e29909/81c8e/Mosquitto_13.png 230w", "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/08a84/Mosquitto_13.png 460w", "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/e9beb/Mosquitto_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eeba646b6f8fab5f50b9b7edd4e29909/e9beb/Mosquitto_13.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "mqtt-binding-for-openhab",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-binding-for-openhab",
        "aria-label": "mqtt binding for openhab permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Binding for OpenHAB`}</h2>
    <p>{`To be able to use the MQTT Protocol in OpenHAB, you need to install the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#connect-the-mqtt-server-to-openhab2"
      }}>{`MQTT Binding`}</a>{` from the OpenHAB Paper UI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51078ac096df9117650bff4fabace363/e9beb/Mosquitto_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABRUlEQVQY032KzU7CQBSF+xAqLExBFurKZ1EgEWqCGx/Dva/hyp+9C8QgC0mkFF0aNyohQGNI6cx02pm5nek1xQ2JiV9OvpycHCvhXPjTpT9ncawvWvJ0H1p70LDBKUOzvHJprZTA2YGmnfvswCKUyJiDUqA1nB/K6iYcb8PJrqoVZb2oqlvSqahaQdWL0qnIhg1HG7JpS6cMtYLFoijVGleoNzfu36nXR3FzqQYdOerKwb0Y9YTbEe6DeOkJb7V43WTYlYO2FUdMJUmWpmiMRlQGFwppmi2FYWkGiP/Eem93Pp/6M3c47T/DfGYQr0f6y+c8XHBGETPMTO68aDR5jE5Dz9M8shbjMfF9EQQyCIyQiPgx+SY04pwzxgghlNIwDBlj2S/GZIjR1a0mxMI/zKYTSgmljDEWxzEACCGUUusfaYw25gcOG14/9NIqbgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51078ac096df9117650bff4fabace363/e4706/Mosquitto_14.avif 230w", "/en/static/51078ac096df9117650bff4fabace363/d1af7/Mosquitto_14.avif 460w", "/en/static/51078ac096df9117650bff4fabace363/b6582/Mosquitto_14.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51078ac096df9117650bff4fabace363/a0b58/Mosquitto_14.webp 230w", "/en/static/51078ac096df9117650bff4fabace363/bc10c/Mosquitto_14.webp 460w", "/en/static/51078ac096df9117650bff4fabace363/87ca7/Mosquitto_14.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51078ac096df9117650bff4fabace363/81c8e/Mosquitto_14.png 230w", "/en/static/51078ac096df9117650bff4fabace363/08a84/Mosquitto_14.png 460w", "/en/static/51078ac096df9117650bff4fabace363/e9beb/Mosquitto_14.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51078ac096df9117650bff4fabace363/e9beb/Mosquitto_14.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      